import { gql } from "@apollo/client";
export const GET_PRODUCT_QUERY =gql `
    query GetProductsperPage(
    $page: Int!, 
    $perPage: Int!, 
    $searchQuery: String){
    getProductsperPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
        totalPages
        totalCount
        product {
            id
            product_name
            product_description
            product_type
            product_code
            hsn_code
            gst
            stock_warning
            unit
            open_stock
            date
            sales_price
            sales_gst
            purchase_price
            purchase_gst
        }
    }
}
`;

export const GET_PARTIES_QUERY =gql `
    query GetPartiesperPage(
    $page: Int!, 
    $perPage: Int!, 
    $searchQuery: String){
    getPartiesperPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
        totalPages
        totalCount
        party {
            id
            party_type
            customer_id
            supplier_id
            name
            email
            phone
            state
            gstin
            shipping_addr
            billing_addr
            credit_period
            credit_limit
            open_bal
            bal_type
        }
    }
}
`;
export const GET_STOCK_QUERY =gql `
    query GetStocksperPage(
    $page: Int!, 
    $perPage: Int!, 
    $searchQuery: String){
    getStocksperPage(
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
    ) {
        totalPages
        totalCount
        product {
            id
            product_name
            product_description
            product_type
            product_code
            hsn_code
            gst
            stock_warning
            unit
            open_stock
            date
            sales_price
            sales_gst
            purchase_price
            purchase_gst
        }
    }
}
`;