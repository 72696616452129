import React from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import PheonixButtonProps from '../interfaces/PheonixButtonProps';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";


const PheonixButton: React.FC<PheonixButtonProps> = ({ label,disabled,onClick, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MuiButton disabled={disabled} onClick={onClick} variant="outlined"  style={{ backgroundColor:disabled ? 'rgba(0, 0, 0, 0.33)' : '#152726',color: 'white', padding: '5px', width: isMobile ? '250px' :'350px', height: '42px',borderRadius:'4px',border:  '1px',boxSizing:'border-box'  }} {...props} >
      {label}
    </MuiButton>
  );
};
export default PheonixButton;