import React,{useState,useEffect} from 'react';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import PheonixSnackBarProps from '../interfaces/PheonixSnackBarProps';
import themestyle from '../theme'
const PheonixSnackBar: React.FC<PheonixSnackBarProps> = ({ open, onClose, message, vertical, horizontal,customAction,severity   }) => {
  const anchorOrigin: SnackbarOrigin = { vertical, horizontal };
  const [snackbarOpen, setSnackbarOpen] = useState(open);
  const backgroundColor = severity === 'success' ? themestyle.colors.primary : '#D32F2F';
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setSnackbarOpen(false);
        onClose && onClose();
      }, 3000); 
    }
  }, [open, onClose]);
return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      onClose={() => {
        setSnackbarOpen(false);
        onClose && onClose();
      }}
      message={message}
      action={customAction}
      sx={{   
        "& .MuiSnackbarContent-root": {
            background: backgroundColor,
          }
        }}/>
  );
};
export default PheonixSnackBar;