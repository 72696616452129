import { baseurl } from "./const";
let baseUrl: string;
if (process.env.REACT_APP_PORT === '7001') {
    baseUrl = baseurl.DEV_URL;
} else if (process.env.REACT_APP_PORT === '7002') {
    baseUrl = baseurl.UAT_URL;
} else if (process.env.REACT_APP_PORT === '7004') {
    baseUrl = baseurl.DEMO_URL;
} else {
    baseUrl = baseurl.LOCAL_URL;
}
export default baseUrl;

