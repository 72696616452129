import React from 'react';
import Login from './Pages/Login';


function App() {
  return (
    <div style={{backgroundColor:'white'}} >
      <Login />
    </div>
  );
}

export default App;
