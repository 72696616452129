import React, { useState,useEffect } from "react";
import { Typography, useMediaQuery, useTheme, IconButton,Box,Button } from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { TEXT_MESSAGES } from "../const";
import { GridColDef } from "@mui/x-data-grid";
import PheonixDataGrid from "../Components/PheonixDataGrid";
import themestyle from "../theme";
import EditIcon1 from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import PheonixPaper from "../Components/PheonixPaper";
import ModalFormData from "../interfaces/ModalFormDataProps";
import axios from "axios";
import { GET_PARTIES_QUERY } from "../graphql/query";
import PheonixBreadcrumbs from "../Components/PheonixBreadcrumbs";


const Parties: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isWideScreen = useMediaQuery("(min-width:600px)");
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const [dataLoading, setdataLoading] = useState(false);
  const [tableData, setTableData] = useState<ModalFormData[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [breadcrumbText, setBreadcrumbText] = useState<string>(''); 
  const {
    loading: customerLoading,
    error: customerError,
    data: customerData,
    refetch: partyRefetch,
  } = useQuery(GET_PARTIES_QUERY, {
    variables: {
      page: paginationModel.page,
      perPage: paginationModel.pageSize,
    },
    onCompleted: (data) => {
      const { totalPages, totalCount, party } = data.getPartiesperPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const partyWithSerialNo = party.map(
        (party: ModalFormData, index: number) => ({
          ...party,
          serial_no: (startIndex + index).toString(),
        })
      );
      setTableData(partyWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
    },
  });
  useEffect(() => {
    setdataLoading(true);
    if (!customerLoading && !customerError && customerData) {
      const { totalPages, totalCount, party } =
        customerData.getPartiesperPage;
      const startIndex = paginationModel.page * paginationModel.pageSize + 1;
      const partyWithSerialNo = party.map(
        (party: ModalFormData, index: number) => ({
          ...party,
          serial_no: (startIndex + index).toString(),
        })
      );

      setTableData(partyWithSerialNo);
      setTableCount(totalCount);
      setTotalPages(totalPages);
      setdataLoading(false);
    }
  }, [
    customerData,
    customerLoading,
    customerError,
    paginationModel.page,
    paginationModel.pageSize,
  ]);
  useEffect(() => {
    partyRefetch();
  }, [partyRefetch]);


  const handlePaginationChange = (newModel: any) => {
    setPaginationModel((prevModel) => ({
      ...prevModel,
      ...newModel,
    }));
  };
  const handleAddParty =()=>{
    setBreadcrumbText(TEXT_MESSAGES.PARTY_BREAD)
  }

  const columns: GridColDef[] = [
    { field: "serial_no", headerName: "S.N0", width: 100 },
    { field: "name", headerName: "Parties Name", width: 150 },
    { field: "phone", headerName: "Mobile Number", flex: 1, minWidth: 150 },
    { field: "party_type", headerName: "Party Type", flex: 1, minWidth: 250 },
    { field: "open_bal", headerName: "Balance", flex: 1, minWidth: 250 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            marginLeft: "-8px",
          }}
        >
          <IconButton onClick={() => {}}>
          <EditIcon1
              style={{
                width: "20px",
                height: "20px",
                color: themestyle.colors.gray,
                marginTop: "7px",
              }}
            />
          </IconButton>
          <IconButton onClick={() => {}}>
            <DeleteIcon
              style={{
                width: "20px",
                height: "20px",
                color: themestyle.colors.gray,
                marginTop: "7px",
              }}
            />
          </IconButton>
        </div>
      ),
      align: "center",
    },
  ];


  return (
    <div>
           <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                
              }}
            >
              <div style={{display:'flex',flexDirection:'column',
                padding: isMobileSmall ? "15px 25px" : "15px 30px",marginLeft:'-12px'}}>
              <Typography
                style={{
                  color: themestyle.colors.black,
                  fontWeight: themestyle.fontWeight.sm,
                  fontSize: isMobileSmall
                    ? themestyle.fontSizes.sm
                    : themestyle.fontSizes.medium,
                }}
              >
                {TEXT_MESSAGES.PARTY_TXT}
              </Typography>
                <PheonixBreadcrumbs breadcrumbText={breadcrumbText}/>
                </div>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                <Button
                style={{
                  background: themestyle.colors.Primary2,
                  color: themestyle.colors.white,
                  width: "165px",
                  height:'43px',
                  marginRight:'15px'
                }}
                onClick={handleAddParty}
              >
                {TEXT_MESSAGES.ADD_PARTY}
              </Button>
         </div>
            </div>
      <Box style={{ padding: '15px' }}>
      <PheonixPaper style={{ borderRadius: "4px" }}>
        <div style={{ width: "100%", overflowX: "auto" }}>
          <PheonixDataGrid
            columns={columns}
            rows={tableData}
            loading={dataLoading}
            rowCount={tableCount}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationChange}
          />
        </div>
      </PheonixPaper>
      </Box>
    </div>
  );
};
export default Parties;