export const TEXT_MESSAGES = {
    LOG_BUTTON:"Sign in to Dasboard",
    EMAIL_REG: /^[a-zA-Z0-9._%+-]+@(?!.*\.\w{0,2}$)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    LOG_TXT:"Login",
    FORGOT_TXT:"Forgot Password",
    ENTER:"Enter",
    Progresstext:"In the works, coming your way!",
    PARTY_TXT:"Parties",
    PRODUCT:"Products",
    STOCKS:"Stocks",
    ADD_PARTY:"+ Add Party",
    ADD_STOCK:"+ Add Stock",
    ADD_PRODUCT:"+ Add Product",
    STOCK_BREAD:"Add Stock",
    PARTY_BREAD:"Add Party",
    PDT_BREAD:"Add Product"

  };

  export const ERROR_MESSAGES = {
    ENTER_BOTH: "Please enter both email and password",
    VALID_EMAIL: "Please enter a valid email address",
    VALID_PHONE: "Phone number must be exactly 10 digits",
    VALID_PASSWORD: "Please enter password",
    PWD_LENGTH: "Password should be minimum of 8 characters",

   
  };
  export const baseurl = {
  DEV_URL: "https://dev-api-taxary.pheonixsolutions.com/graphql",
  UAT_URL: "https://uat-api-taxary.pheonixsolutions.com/graphql",
  DEMO_URL:"https://demo.api-taxary.net/graphql",
  LOCAL_URL: "http://localhost:5000/graphql",
  }