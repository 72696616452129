import React, { ChangeEvent, useState, useEffect, useContext,ReactNode  } from "react";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import PheonixTextField from "../Components/PheonixTextField";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { TEXT_MESSAGES, ERROR_MESSAGES } from "../const";
import SnackBar from "../Components/PheonixSnackBar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import logo from "../assets/translogo.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import baseUrl from "../api";
import axios from "axios";
import { LOGIN_USER } from "../graphql/mutation";
import PheonixButton from "../Components/PheonixButton";
import { Stack } from "@mui/material";
import { Container, styled } from "@mui/material";
const Item = styled("div")({
  marginBottom: "15px",
  padding: "8px",
  textAlign: "center",
});

const Login: React.FC = () => {
  const theme = useTheme();
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [pasErrorMessage, setPasErrorMessage] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [succMessage, setSuccMessage] = useState<ReactNode>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error"
  );

  const navigate = useNavigate();
  const handleemailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setemail(e.target.value);
  };
  const handlepasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setpassword(e.target.value);
  };

  const handleEmailFocus = () => {
    setErrorMessage("");
  };
  const handleEmailBlur = () => {
    const emailRegex = TEXT_MESSAGES.EMAIL_REG;
    if (!emailRegex.test(email)) {
      setErrorMessage(ERROR_MESSAGES.VALID_EMAIL);
    } else {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    localStorage.removeItem("email");
    const emailValid = isValidEmail(email);

    if (!emailValid || !password) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [email, password]);

  function isValidEmail(email: string) {
    const emailRegex = TEXT_MESSAGES.EMAIL_REG;
    return emailRegex.test(email);
  }
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordBlur = () => {
    if (!password) {
      setPasErrorMessage(ERROR_MESSAGES.VALID_PASSWORD);
    } else {
      setPasErrorMessage("");
    }
  };

  const handleClose = () => {
    setModalOpen(false);
    navigate("/home");
  };

  const handlePasswordFocus = () => {
    setPasErrorMessage("");
  };
  const handleLogin = async () => {
    try {
      const response = await axios.post(baseUrl, {
        query: LOGIN_USER,
        variables: {
          email: email,
          password: password,
        },
      });
      const { success, message } = response.data.data.loginUser;
      if (success) {
        setSuccMessage(
          <span style={{ display: "flex", alignItems: "center" }}>
            <CheckCircleIcon style={{ marginRight: "8px" }} />
           {message}
          </span>
        );
        setSnackbarSeverity("success");
        setModalOpen(true);
        setTimeout(() => {
          navigate("/home");
          setemail("");
          setpassword("");
        }, 3000);
      } else {
        setSnackbarSeverity("error");
        const error = message.toLowerCase();
        if (error.includes("email")) {
          setErrorMessage(message);
        } else if (error.includes("password")) {
          setPasErrorMessage(message);
        } else {
          setErrorMessage(message);
        }
      }
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === TEXT_MESSAGES.ENTER) {
      handleLogin();
    }
  };

  const customCloseIcon = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} style={{ justifyContent: 'end', width: '200px' }} disableRipple>
      <CloseIcon fontSize="small" />
    </IconButton>
  );



  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background:
            "linear-gradient(194.32deg, #85E956 2.15%, #30BBC4 85.53%)",
          width: isMobileSmall ? 'auto' : "422px",
          height: "506px",
          gap: "10px",
          borderRadius: "8px",
          textAlign: "center",
          top: "125px",
        }}
      >
        <Box component="form" onKeyPress={handleKeyPress}>
          <Stack spacing={2} direction="column">
            <Item>
              <img
                src={logo}
                alt="Logo"
                style={{ width: "231px", height: "54px" }}
              />
            </Item>
            <Item>
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "32px",
                  letterSpacing: "0.15px",
                  color: "#333333",
                }}
              >
                {TEXT_MESSAGES.LOG_TXT}
              </Typography>
            </Item>
            <Item>
              <PheonixTextField
                id="outlined-error"
                type="email"
                value={email}
                placeholder="Email"
                label="Email"
                variant="outlined"
                onChange={handleemailChange}
                onFocus={handleEmailFocus}
                onBlur={handleEmailBlur}
                error={Boolean(errorMessage)}
                helperText={errorMessage || ""}
                style={{
                  width: isMobileSmall ? "250px" : "350px",
                  backgroundColor: "#A9E7CB",
                  borderRadius: "8px",
                  border: "1px",
                }}
              />
            </Item>
            <Item>
              <PheonixTextField
                id="outlined-error"
                type={showPassword ? "text" : "password"}
                value={password}
                placeholder="Password"
                label="Password"
                variant="outlined"
                onChange={handlepasswordChange}
                onFocus={handlePasswordFocus}
                onBlur={handlePasswordBlur}
                error={Boolean(pasErrorMessage)}
                helperText={pasErrorMessage || ""}
                style={{
                  width: isMobileSmall ? "250px" : "350px",
                  backgroundColor: "#A9E7CB",
                  borderRadius: "8px",
                  border: "1px",
                }}
                disabled={Boolean(errorMessage)}
                endAdornment={
                  <InputAdornment
                    position="end"
                    style={{
                      cursor: "pointer",
                      marginTop: "0px",
                      color: "rgba(0, 0, 0, 0.56)",
                    }}
                    onClick={handleTogglePassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </InputAdornment>
                }
              />
            </Item>
            <Item>
              <PheonixButton
                label={TEXT_MESSAGES.LOG_TXT}
                onClick={handleLogin}
                sx={{ textTransform: "none" }}
                disabled={buttonDisabled}
                disableRipple
              />
            </Item>
          </Stack>
        </Box>
      </Box>
      <SnackBar
          open={modalOpen}
          onClose={handleClose}
          message={succMessage}
          vertical="top"
          horizontal="center"
          customAction={customCloseIcon}
          severity={snackbarSeverity}
        />

    </div>
  );
};
export default Login;
